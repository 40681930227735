import React, { useMemo, useState } from 'react';
import { EducationProfile, usePortalCourses } from '@brainstud/academy-api';
import { Accordion, Button, Checkbox, Dropdown, Search } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { FilterSidebarButton } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { useCourseList } from '../../../../../../Providers';
import { sbuSorter } from '../SbuSortFn';
import styles from './FilterDrawer.module.css';

const cx = classNames.bind(styles);

const FilterDrawer = () => {
  const [t] = useTranslator();
  const [, { data: document }] = usePortalCourses();
  const educationProfiles = useMemo(
    () => document?.findByType<EducationProfile>('education_profiles') || [],
    [document]
  );
  const [showMore, setShowMore] = useState(false);
  const [profileSearchQuery, setProfileSearchQuery] = useState('');

  const { catalog, filters, sort, dispatch } = useCourseList();

  const sbuValues: string[] = [
    ...new Set(
      catalog
        .map(
          (course) =>
            course?.metadata?.informationLines?.sbu ||
            // @ts-ignore FIXME: Or can be removed when all courses have adopted the informationLines approach.
            course?.metadata?.labels?.sbu
        )
        .filter(Boolean)
        .sort(sbuSorter)
    ),
  ];

  // profiles filter search and show more show less
  const profiles = useMemo(() => {
    if (profileSearchQuery.length > 1) {
      return educationProfiles.filter((profile) =>
        profile.title.toLowerCase().includes(profileSearchQuery.toLowerCase())
      );
    }
    return showMore ? educationProfiles : educationProfiles.slice(0, 6);
  }, [educationProfiles, profileSearchQuery, showMore]);

  const filterCounter =
    (filters?.sbu?.length || 0) + (filters?.profiles?.length || 0);

  return (
    <FilterSidebarButton
      label={t('components.catalog.filter')}
      title={t('components.catalog.filter')}
      onReset={() => dispatch({ type: 'RESET' })}
      count={filterCounter}
    >
      <Dropdown
        label={t('components.catalog.sort_on')}
        placeholder={t('components.catalog.sort_on')}
        block
        value={sort.attribute}
        onChange={(attribute) =>
          dispatch({
            type: 'SET_SORT',
            payload: { attribute: attribute || undefined },
          })
        }
        className={cx(styles.dropdown)}
      >
        <Dropdown.Option value="createdAt">
          {t('components.catalog.sort_createdAt')}
        </Dropdown.Option>
        <Dropdown.Option value="title">
          {t('components.catalog.sort_title')}
        </Dropdown.Option>
        <Dropdown.Option value="sbu">
          {t('components.catalog.sbu')}
        </Dropdown.Option>
      </Dropdown>
      <div className={styles['sort-toggle']}>
        <button
          type="button"
          disabled={sort.direction === 'ascending'}
          onClick={() => dispatch({ type: 'TOGGLE_SORT_DIRECTION' })}
        >
          {t('components.catalog.sort_ascending')}
        </button>
        |
        <button
          type="button"
          disabled={sort.direction === 'descending'}
          onClick={() => dispatch({ type: 'TOGGLE_SORT_DIRECTION' })}
        >
          {t('components.catalog.sort_descending')}
        </button>
      </div>

      {sbuValues.length > 1 && (
        <Accordion
          quiet
          title={t('components.catalog.filter_panel.other')}
          className={cx(styles.accordion)}
        >
          {sbuValues.map((sbuValue, idx) => (
            <Checkbox
              quiet
              id={idx}
              name="sbu"
              key={sbuValue || 'none'}
              className={cx(styles.sbuInput)}
              defaultChecked={filters?.sbu?.includes(sbuValue || 'none')}
              defaultValue={sbuValue || 'none'}
              onChange={(e) =>
                dispatch({
                  type: 'FILTER_SBU',
                  payload: e.target.checked
                    ? [...(filters?.sbu || []), sbuValue]
                    : filters?.sbu?.filter((value) => value !== sbuValue),
                })
              }
            >
              {`${sbuValue || t('components.catalog.sbu_none')}`}
            </Checkbox>
          ))}
        </Accordion>
      )}

      {(educationProfiles?.length || 0) > 1 && (
        <Accordion
          quiet
          title={t('components.catalog.filter_panel.profiles')}
          className={cx(styles.accordion)}
        >
          <Search
            placeholder={t('components.catalog.filter_panel.search_profiles')}
            onChange={(e) => setProfileSearchQuery(e.target.value)}
            className={cx(styles.search)}
            block
          />
          {profiles?.map((profile) => (
            <Checkbox
              quiet
              key={profile.id}
              id={profile.title}
              className={cx(styles.profile)}
              defaultChecked={filters?.profiles?.includes(profile.id)}
              onChange={(e) =>
                dispatch({
                  type: 'FILTER_PROFILES',
                  payload: e.target.checked
                    ? [...(filters?.profiles || []), profile.id]
                    : filters?.profiles?.filter(
                        (value) => value !== profile.id
                      ),
                })
              }
              name={profile.title}
            >
              {profile.title}
            </Checkbox>
          ))}
          {profiles.length > 5 && (
            <Button
              link
              onClick={() => setShowMore((prevShowMore) => !prevShowMore)}
              className={cx(styles['link-button'])}
            >
              {t(
                `components.catalog.filter_panel.show.${!showMore ? 'more' : 'less'}`
              )}
            </Button>
          )}
        </Accordion>
      )}
    </FilterSidebarButton>
  );
};

export default FilterDrawer;
