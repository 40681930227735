import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Course } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { useBreakpoints } from 'Hooks';
import {
  CourseCard,
  CourseInfoPanel,
} from 'Modules/blended-learning-catalog-panel/Components';
import { useCourseList } from 'Modules/blended-learning-catalog-panel/Providers';
import {
  groupPer,
  isCompleted,
  isNew,
} from 'Modules/blended-learning-catalog-panel/Utils';
import queryString from 'query-string';
import styles from './CatalogGrid.module.css';

const cx = classNames.bind(styles);

type Props = {
  passedCourses?: Course[];
};

const CatalogGrid = ({ passedCourses }: Props) => {
  const { courses: providedCourses, quickStart } = useCourseList();
  const { course: preOpenedCourseId } = queryString.parse(
    window.location.search
  ) as { course?: string };
  const [openCourseId, setOpenCourseId] = useState<string | number | null>(
    (!quickStart && preOpenedCourseId) || null
  );
  const [row, setRow] = useState<number | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const courses = passedCourses || providedCourses;
  const courseRows = useMemo(() => groupPer(4, courses), [courses]);

  useEffect(() => {
    if (preOpenedCourseId === openCourseId && !quickStart) {
      setRow(
        courseRows.findIndex((courseRow) =>
          courseRow.some((course) => course.id === openCourseId)
        )
      );
    }
  }, [courseRows, preOpenedCourseId, openCourseId, quickStart]);

  const toggleCourseInfoPanelOnRow = (
    rowNr: number | null = null,
    courseId: string | number | null = null
  ) => {
    if (openCourseId === courseId) {
      setOpenCourseId(null);
      setRow(null);
    } else {
      setOpenCourseId(courseId);
      setRow(rowNr);
    }
  };

  useEffect(() => {
    if (row === null) return;
    scrollRef.current
      ?.getElementsByClassName(`row-${row}`)[0]
      ?.scrollIntoView(true);
  }, [row]);

  const { mdUp, smDown } = useBreakpoints();

  return (
    <div
      ref={scrollRef}
      className={cx('grid', { hasInfoPanel: Boolean(openCourseId) })}
    >
      <div className={styles.flexContainer}>
        {courseRows.map((group, i) => (
          <React.Fragment key={group.map((course) => course.id).join('+')}>
            <div className={cx(styles.flexGroup, `row-${i}`)}>
              {group.map((course) => (
                <CourseCard
                  key={course.id}
                  courseId={course.id}
                  className={cx(styles.card, {
                    isActive: openCourseId === course.id,
                  })}
                  isOpened={openCourseId === course.id}
                  isNew={isNew(course)}
                  isCompleted={!!isCompleted(course)}
                  openInfoPanel={() => toggleCourseInfoPanelOnRow(i, course.id)}
                />
              ))}
            </div>
            {mdUp && row === i && !!openCourseId && (
              <CourseInfoPanel
                course={openCourseId}
                onClose={() => toggleCourseInfoPanelOnRow()}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      {smDown && !!openCourseId && (
        <CourseInfoPanel
          course={openCourseId}
          onClose={() => toggleCourseInfoPanelOnRow()}
        />
      )}
    </div>
  );
};

export default CatalogGrid;
