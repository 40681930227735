import React, { useMemo } from 'react';
import { Portal, useMe } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useUserData } from 'Hooks';
import { useTranslator } from 'Providers/Translator';
import styles from './Hero.module.css';

const cx = classNames.bind(styles);

type TProps = {
  portal?: Portal;
  force?: boolean;
  className?: string;
};

const Hero = ({ portal: givenPortal, force, className }: TProps) => {
  const [me, { isLoading }] = useMe();
  const [t] = useTranslator();
  const [{ isAuthenticated }] = useUserData();

  const portal = useMemo(
    () => givenPortal || me?.portal?.(),
    [me, givenPortal]
  );
  const scroll = () =>
    document?.getElementById('scroll')?.scrollIntoView({ behavior: 'smooth' });
  const placeholderImg = '/media/abstract-hero.jpg';

  return isAuthenticated && !force ? null : (
    <div id="top" className={cx(styles.hero, className)}>
      {isLoading || !portal ? (
        <Loading />
      ) : (
        <>
          <img src={portal.hero?.image || placeholderImg} alt="" />
          <div className={styles.texts}>
            <h1>{portal.hero?.title}</h1>
            <p>{portal.hero?.text}</p>
            <Button className={styles.button} onClick={scroll}>
              {t('components.hero.cta')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Hero;
