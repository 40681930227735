import React, { ReactNode, useEffect, useMemo } from 'react';
import {
  Course,
  ProductCategory,
  usePortalCourses,
} from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useCourseList } from 'Modules/blended-learning-catalog-panel/Providers';
import { isCompleted } from 'Modules/blended-learning-catalog-panel/Utils';
import { useTranslator } from 'Providers/Translator';
import CatalogGrid from './CatalogGrid/CatalogGrid';
import CatalogHeader from './CatalogHeader/CatalogHeader';
import CatalogList from './CatalogList/CatalogList';
import styles from './Catalog.module.css';

const cx = classNames.bind(styles);

type CatalogProps = {
  id?: string;
  title: ReactNode | ((category?: { labelPlural?: string }) => ReactNode);
  headless?: boolean;
  category?: string;
};

const Catalog = ({ id, title, headless, category }: CatalogProps) => {
  const { viewType, courses, dispatch, searchQuery, filters } = useCourseList();
  const hasFilters = Object.keys(filters).some((key) =>
    Array.isArray(filters[key] ? filters[key].length : !!filters[key])
  );
  const [t] = useTranslator();
  const [, { data: document }] = usePortalCourses();
  const productCategories = useMemo(
    () => document?.findByType<ProductCategory>('product_categories'),
    [document]
  );
  const currentCategory = productCategories?.find((pc) => pc.id === category);

  const sortedCourses = useMemo(
    () =>
      courses.sort((a: Course, b: Course) => {
        if (!a.enrollment?.() || !b.enrollment?.()) return 0;
        if (!isCompleted(a) && isCompleted(b)) return -1;
        if (isCompleted(a) && !isCompleted(b)) return 1;
        return 0;
      }),
    [courses]
  );

  useEffect(() => {
    if (category) {
      dispatch({
        type: 'FILTER_CATEGORY',
        payload: category,
      });
    }
  }, [category, dispatch]);

  return (
    <>
      <Container id={id} className={cx(styles.header)}>
        {typeof title === 'function' ? title(currentCategory) : title}
      </Container>

      {!headless && <CatalogHeader />}

      {sortedCourses.length === 0 ? (
        <Container className={cx(styles['no-courses'])}>
          <span>
            {t(
              `components.catalog.no_courses.${searchQuery || hasFilters ? 'search' : 'no_results'}`
            )}
          </span>
        </Container>
      ) : viewType === 'grid' ? (
        <CatalogGrid passedCourses={sortedCourses} />
      ) : (
        <CatalogList passedCourses={sortedCourses} />
      )}
    </>
  );
};

export default Catalog;
